import SearchIcon from '@mui/icons-material/Search';

import { PlatformAccordianTypes } from '@/type';

import PlatformAccordian from './PlatformAccordian';

const PlatformInstructions = () => {
  return (
    // <div className=" mt-[40px] w-[88%] rounded-lg bg-trans-white mt-4 p-4 pt-5  md:w-[80%] lg:w-[86%] [@media(min-width:1400px)]:w-[90%]">
    <div className="rounded-lg bg-trans-white p-4">
      {/* <div className="flex justify-end pr-4">
        <div className="w-[40%] border text-grey-ghoose text-xs rounded-full bg-white pl-4 flex justify-between p-2">
          <input
            type="text"
            placeholder="輸入想搜尋的關鍵字"
            className="w-[60%] outline-none text-xs leading-[14.52px] tracking-[7%] font-bold"
          />
          <div className="border-l-2 w-[20%] flex justify-end ">
            <SearchIcon className="text-[26px]" />
          </div>
        </div>
      </div> */}
      <div className=" mt-3 overflow-y-scroll yellowScroll h-[500px] [@media(min-width:1400px)]:h-[690px]  [@media(min-width:1700px)]:h-[710px]">
        <div className="w-full p-5 pt-0">
          {INSTRUCTION_CARD_DATA.map(({ id, title, items }: PlatformAccordianTypes, index) => {
            return <PlatformAccordian key={index} currentIndex={id} title={title} items={items} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default PlatformInstructions;

export const INSTRUCTION_CARD_DATA: PlatformAccordianTypes[] = [
  // {
  //   id: 1,
  //   title: '平台使用說明',
  //   items: [
  //     {
  //       name: '如何查詢舊訂單？',
  //       description: ''
  //     },
  //     {
  //       name: '如何取消訂單？',
  //       description: ''
  //     }
  //   ]
  // },
  {
    id: 2,
    title: '權限設定說明',
    items: [
      {
        name: '如何更改權限？',
        description: '可由管理員至企業帳號總覽，選擇欲修改權限之帳號，進入資料修改畫面進行操作權限的修改。'
      }
      // {
      //   name: '權限的區分？',
      //   description: '請參照權限區分表。'
      // }
    ]
  },
  {
    id: 3,
    title: '帳號常見問題',
    items: [
      {
        name: '如何修改會員資料？',
        description: '如欲修改自己的帳號資料，可到帳號資訊>個人資訊>變更資料進行修改，但不可修改帳號與權限。'
      },
      {
        name: '忘記密碼如何重新設定？',
        description: '如忘記密碼可透過登入頁面的 Forgot Password進行密碼的重新設定。'
      }
    ]
  }
];
