import classNames from 'classnames';

import { Filters } from '@/store/filterOptions';
import { useProductListStore } from '@/store/productList';
import { CarbonTag } from '@/type';

import CustomButton from '../CustomButton';

type LayoutSwitchProps = {
  filters: Filters;
  updateFilter: (filters: Filters) => void;
};

const LayoutSwitch = ({ filters, updateFilter }: LayoutSwitchProps) => {
  return (
    <div className=" pt-2 mb-8 flex gap-6 items-center justify-end h-[46px]">
      <CustomButton
        variant="rounded-full"
        className={classNames(
          '!bg-[#B5B5B6] !text-white !text-[15px] tracking-[1.425px] pt-0.5 !shadow-button-shadow',
          {
            ['w-[205px] h-7 flex justify-center items-center']: filters.tag === CarbonTag.White,
            ['w-[90px] h-[18px]']: filters.tag !== CarbonTag.White
          }
        )}
        onClick={() => {
          updateFilter({
            tag: CarbonTag.White
          });
        }}
      >
        {filters.tag === CarbonTag.White ? '碳權總覽' : ''}
      </CustomButton>

      <CustomButton
        variant="rounded-full"
        className={classNames('!bg-dark-green !text-[16.48px] tracking-[1.566px] !shadow-button-shadow', {
          ['text-white w-[205px] h-7 flex justify-center items-center']: filters.tag === CarbonTag.Green,
          ['w-[90px] h-[18px]']: filters.tag !== CarbonTag.Green
        })}
        onClick={() => {
          updateFilter({
            tag: CarbonTag.Green
          });
        }}
      >
        {filters.tag === CarbonTag.Green ? '綠碳' : ''}
      </CustomButton>
      <CustomButton
        variant="rounded-full"
        className={classNames('bg-yellow !text-[15px] tracking-[1.425px] !shadow-button-shadow', {
          ['text-white w-[205px] h-7 flex justify-center items-center']: filters.tag === CarbonTag.Yellow,
          ['w-[90px] h-[18px]']: filters.tag !== CarbonTag.Yellow
        })}
        onClick={() => {
          updateFilter({
            tag: CarbonTag.Yellow
          });
        }}
      >
        {filters.tag === CarbonTag.Yellow ? '黃碳' : ''}
      </CustomButton>
      <CustomButton
        variant="rounded-full"
        className={classNames('bg-blue !text-[15px] tracking-[1.425px] !shadow-button-shadow', {
          ['text-white w-[205px] h-7 flex justify-center items-center']: filters.tag === CarbonTag.Blue,
          ['w-[90px] h-[18px]']: filters.tag !== CarbonTag.Blue
        })}
        onClick={() => {
          updateFilter({
            tag: CarbonTag.Blue
          });
        }}
      >
        {filters.tag === CarbonTag.Blue ? '藍碳' : ''}
      </CustomButton>
      <CustomButton
        variant="rounded-full"
        className={classNames('bg-white !text-[#525252] tracking-[1.425px] !shadow-button-shadow', {
          ['text-white w-[205px] h-7 flex justify-center items-center']: filters.tag === CarbonTag.Other,
          ['w-[90px] h-[18px]']: filters.tag !== CarbonTag.Other
        })}
        onClick={() => {
          updateFilter({
            tag: CarbonTag.Other
          });
        }}
      >
        {filters.tag === CarbonTag.Other ? '其他' : ''}
      </CustomButton>
    </div>
  );
};

export default LayoutSwitch;
