import { yupResolver } from '@hookform/resolvers/yup';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useAccountSteps } from '@/store/accountSteps';
import { useEmployeeStore } from '@/store/employee';
import { AccountStepsEnum } from '@/type';
import { userInfoSchema } from '@/util/schema';

import CustomButton from '../CustomButton';
import CustomInput from './CustomInput';
import CustomSelect from './CustomSelect';

const schema = userInfoSchema;

export type UserInfoFormValues = yup.InferType<typeof schema>;

const UserInfoForm = () => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<UserInfoFormValues>({
    defaultValues: {
      group_name: '管理員'
    },
    resolver: yupResolver(schema)
  });

  const roleList = useEmployeeStore((store) => store.roleList);
  const getRoleList = useEmployeeStore((store) => store.getRoleList);
  const createEmployee = useEmployeeStore((store) => store.createEmployee);
  const navigate = useNavigate();
  useAccountSteps((state) => state.step);

  console.log('errors', errors);

  const confirm = watch('confirm');

  useEffect(() => {
    getRoleList();
    useAccountSteps.setState({ step: AccountStepsEnum.ENTERPRISE_ACCOUNT });
  }, []);

  const [file, setFile] = useState<string>('/v2/user-info-form/default.svg');
  const [fileSource, setFileSource] = useState<File | null>(null);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      try {
        setFile(URL.createObjectURL(event.target.files[0]));
        setFileSource(event.target.files[0]);
      } catch (err) {
        return;
      }
    }
  }

  // const onSubmit = handleSubmit(async (value) => {
  //   console.log('value', value);
  //   if (!confirm) return;
  //   if (fileSource) {
  //     const isSuccess = await createEmployee(value, fileSource);
  //     if (isSuccess) {
  //       navigate('/v2/enterprise-account');
  //     }
  //   } else {
  //     alert('請上傳照片');
  //   }
  // });
  const onSubmit = handleSubmit(async (value) => {
    if (!confirm) return;
    if (fileSource) {
      const isSuccess = await createEmployee(value, fileSource);
      if (isSuccess) {
        navigate('/v2/enterprise-account');
      }
    } else {
      alert('請上傳照片');
    }
  });

  return (
    <form className="min-w-[800px] 2xl:min-w-[1100px]" onSubmit={onSubmit}>
      {/* <div className="rounded-[10px] px-8 py-6 mt-[100px] 2.5xl:mt-[146px] bg-trans-white flex flex-col min-h-[464px] w-[98%] xl:w-[96%] 2.5xl:w-[90%]"> */}
      <div className="rounded-[10px] bg-trans-white p-8">
        <p className="text-navy-blue text-lg font-bold tracking-[0.72px] leading-normal font-istok-web">| 新增使用者</p>
        <div className="flex gap-4 py-4 2xl:py-10">
          <div className="relative mt-[65px]">
            <IconButton component="label" className="relative z-50 ">
              <label
                className="absolute min-[1600px]:text-sm min-[1500px]:text-xs text-xms z-40 text-white cursor-pointer"
                htmlFor="image"
              >
                更換照片
              </label>
              <Avatar
                src={file}
                className="min-[1600px]:!w-[100px] min-[1600px]:!h-[100px] min-[1500px]:!w-[85px] min-[1500px]:!h-[85px] min-[1300px]:!w-[75px] min-[1300px]:!h-[75px] !w-[60px] !h-[60px]"
              />
              <input type="file" hidden onChange={handleChange} id="image" />
            </IconButton>
          </div>
          <div className="flex flex-col gap-[18px]">
            <CustomInput<UserInfoFormValues>
              errors={errors}
              label="姓名"
              id="last_name"
              type="text"
              register={register}
            />
            <CustomInput<UserInfoFormValues>
              errors={errors}
              label="職稱"
              id="position"
              type="text"
              register={register}
            />
            <CustomInput<UserInfoFormValues>
              errors={errors}
              label="帳號"
              id="username"
              type="text"
              register={register}
            />
            <CustomInput<UserInfoFormValues> errors={errors} label="Email" id="email" type="text" register={register} />
          </div>
          <div className="flex flex-col gap-y-4.2 max-w-[350px]">
            <CustomInput<UserInfoFormValues>
              errors={errors}
              label="電話"
              id="tel"
              type="text"
              register={register}
              isCreate={true}
            />
            <CustomInput<UserInfoFormValues>
              errors={errors}
              label="分機"
              id="tel_extension"
              type="text"
              register={register}
              className="!w-[60%]"
            />
            <div className="flex gap-2 pl-12 mt-4 relative">
              {confirm ? (
                <img
                  src="/images/enterprise-account/checked-icon.svg"
                  alt="confirm"
                  onClick={() => setValue('confirm', false)}
                  className="w-[22px] h-[22px]"
                />
              ) : (
                <img
                  src="/images/enterprise-account/unchecked-icon.svg"
                  alt="confirm"
                  onClick={() => setValue('confirm', true)}
                  className="w-[22px] h-[22px]"
                />
              )}

              <div className="flex flex-col pr-[6%] 3xl:pr-[22%]">
                <div className="min-h-[67px]">
                  <p
                    className="text-navy-blue text-base font-bold leading-normal font-istok-web z-10"
                    style={{ fontSize: '14px' }}
                    onClick={() => setValue('confirm', !confirm)}
                  >
                    請確認帳號資料填寫無誤，完成新增作業後，系統將自動寄送密碼設定email至指定信箱。
                  </p>
                  {errors['confirm'] && !confirm && (
                    <p className="text-bright-red text-xs font-normal leading-normal">請務必確認勾選此框。</p>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="flex  min-[1600px]:gap-7.5 min-[1500px]:gap-6 min-[1300px]:gap-5.5 gap-5 self-end">
              <p className="min-[1600px]:text-lg min-[1500px]:text-base text-mdbase text-navy-blue font-bold min-[1600px]:mt-2.5 min-[1500px]:mt-2 min-[1300px]:mt-1.5 min-[1200px]:mt-1 mt-0.5 font-istok-web">
                操作權限
              </p>
              <CustomSelect
                setValue={setValue}
                selectedValue={getValues().group_name}
                options={roleList.map((role) => role.name)}
              />
            </div> */}
            <div className="hidden min-[1600px]:gap-7.5 min-[1500px]:gap-6 min-[1300px]:gap-5.5 gap-5 self-end">
              <p className="min-[1600px]:text-lg min-[1500px]:text-base text-mdbase text-navy-blue font-bold min-[1600px]:mt-2.5 min-[1500px]:mt-2 min-[1300px]:mt-1.5 min-[1200px]:mt-1 mt-0.5 font-istok-web">
                操作權限
              </p>
              <CustomSelect
                setValue={setValue}
                selectedValue={getValues().group_name}
                options={roleList.map((role) => role.name)}
              />
            </div>
          </div>
        </div>
        {/* <div className="flex justify-end">
          <div className="flex justify-end items-start gap-2 3xl:max-w-[525px] min-[1600px]:max-w-[415px] min-[1500px]:max-w-[375px] min-[1300px]:max-w-[325px] max-w-[265px]">
            {confirm ? (
              <img
                src="/images/enterprise-account/checked-icon.svg"
                alt="confirm"
                onClick={() => setValue('confirm', false)}
                className="w-[22px] h-[22px]"
              />
            ) : (
              <img
                src="/images/enterprise-account/unchecked-icon.svg"
                alt="confirm"
                onClick={() => setValue('confirm', true)}
                className="w-[22px] h-[22px]"
              />
            )}

            <div className="flex flex-col pr-[6%] 3xl:pr-[22%]">
              <div className="min-h-[67px]">
                <p
                  className="text-navy-blue text-base font-bold leading-normal font-istok-web"
                  onClick={() => setValue('confirm', !confirm)}
                >
                  請確認帳號資料填寫無誤，完成新增作業後，系統將自動寄送密碼設定email至指定信箱。
                </p>
                {errors['confirm'] && !confirm && (
                  <p className="text-bright-red text-xs font-normal leading-normal">請務必確認勾選此框。</p>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <div className="flex gap-4.5 justify-end mt-8">
          <Link to="/v2/enterprise-account">
            <CustomButton
              children="取消"
              variant="secondary"
              className="min-[1600px]:w-31 min-[1600px]:h-8.7 min-[1500px]:w-26.7 min-[1500px]:h-7.5 w-23.2 h-6.7  border rounded-mdlg min-[1600px]:text-lg min-[1500px]:text-base text-sm font-bold"
            />
          </Link>
          <CustomButton
            children="確認"
            variant="primary"
            type="submit"
            className="min-[1600px]:w-31 min-[1600px]:h-8.7 min-[1500px]:w-26.7 min-[1500px]:h-7.5 w-23.2 h-6.7 rounded-mdlg min-[1600px]:text-lg min-[1500px]:text-base text-sm font-bold"
          />
        </div>
      </div>
    </form>
  );
};

export default UserInfoForm;
