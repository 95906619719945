export const getDaysOldDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const convertDateToFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const convertFormatToDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const formatNumberByComma = (value: string | number) => {
  const updatedValue = value?.toString();
  const sanitizedValue = updatedValue?.replace(/,/g, '');
  return Number(sanitizedValue)?.toLocaleString();
};

export const ellipsisText = (text: string, length: number) => {
  return text.length > length ? text.slice(0, length) + '...' : text;
};
